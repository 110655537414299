import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./Issues.css"

export default function IssuesTTC(props){
	return(
		<div className="issues-article-body">
<p>Millions of Torontonians depend on the TTC everyday for work and for play. TTC is the lifeblood of Toronto&rsquo;s economy, and Jack will provide all Torontonians with unlimited and improved access to this basic need to increase Torontonians&rsquo; mobility, job opportunities, and to decrease traffic congestion. He will:</p>

<ul>
<li>Provide free transit for all Torontonians</li>
<li>Immediately slash the cost of monthly passes by ~90%</li> 
<li>Upgrade ticketing infrastructure for better security and rider experience</li>
</ul>

<p className='blue-text-800'>Free Transit for All</p>

<p>TTC&rsquo;s marginal operating cost is largely fixed. Adding an extra passenger to a subway train will not incur additional costs for the city, but the economic benefit of providing the transportation service to the passenger is positive. The passenger may use the TTC to get to work, to shop, or to socialize with friends, activities that all contribute to Toronto&rsquo;s vibrant economy. As Mayor of Toronto, Jack will initiate the Free Transit For All plan with the goal of offering free and unlimited TTC rides for all Torontonians within three years. Based on current estimates, the TTC will generate ~$800 million of passenger revenue in 2022, this budget shortfall will be managed in conjunction with <Link className="article-link" to='/government-waste'>Jack's plan to reduce government waste and cut down on unnecessary expenses</Link>. There will be no tax hikes to fund Free Transit for All.</p>

<p className='blue-text-800'>Immediately Slash the Cost of Monthly Passes by ~90%</p>

<p>During the transition period of Jack&rsquo;s Free Transit for All initiative, he will make it significantly more affordable for Torontonians to participate in Toronto&rsquo;s economy. By decreasing the cost of monthly passes by ~90% from $156 to $20, Jack directly puts thousands of dollars into the pocket of many ordinary Torontonians every year. This immediate price reduction of monthly passes will also make the TTC a much more attractive option for commuters making their way downtown, decreasing traffic congestion during rush hours. No more bumper to bumper morning drive on the DVP.</p>

<p className='blue-text-800'>Upgrade Ticketing Infrastructure to Improve Security and Rider Experience</p>

<p>As part of Jack&rsquo;s Free Transit for All initiative, he will modernize TTC&rsquo;s existing infrastructure to provide better security, more efficient services, and a better riding experience. Turnstyles will be eliminated and tickets will be replaced with RFID tags. All TTC assets will be equipped with readers to effortlessly validate passengers. Existing ticket booth employees will transition to become mobile ticket validators on trains, increasing security and deterring crime. Using RFID technology, TTC employees and law enforcement officers will be alerted to ticket dodgers and other prohibited persons in real time so incidents can be addressed quickly and without delay. </p>
		</div>
	)
}
