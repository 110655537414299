import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./Issues.css"

export default function IssuesHousing(props){
	return(
		<div className="issues-article-body">
<p>Tory&rsquo;s policies have restricted the growth of Toronto&rsquo;s housing market, destroyed jobs, and are choking off the next generation of talent from establishing themselves in Toronto. Under Jack&rsquo;s leadership, major relaxation of zoning laws and affordable housing reform will benefit homeowners and tenants, increase tax revenue, and create more jobs. As Mayor of Toronto, Jack will:</p>

<ul>
<li>Abolish cumbersome zoning laws</li>
<li>Rejuvenate Toronto&rsquo;s economic growth</li>
<li>Eliminate the lottery system in affordable housing and reverse Inclusionary Zoning</li>
</ul>
<p className='blue-text-800'>Abolish Cumbersome Zoning Laws</p>

<p>Jack will immediately reverse Tory&rsquo;s regressive zoning policies in key areas of Toronto. He has hindered Toronto&rsquo;s economic progress and has crippled an entire generation of Torontonians for the benefit of his political allies. Existing zoning bylaws prohibit builders from increasing housing supply in areas of high demand that would otherwise occur naturally in a free market. Take the following snapshot of a section in the Annex.</p>

<p><img style={{width:"100%"}} src='https://storage.googleapis.com/mayor-campaign-2022/zoningmap.jpg'/></p>

<p className='issues-caption'><i>Left: Zoning overlay. R denotes Residential, CR denotes Commercial Residential. Right: Height overlay. Denoted in metres.</i></p>

<p>Large swaths of land are currently zoned solely for residential units and are restricted to just 10 metres, leaving single detached units the only viable option for builders. To tackle this issue, Jack will raise the height limit from 10 metres to 30 metres and replace all residential only zones to mixed-use zones for areas south of St. Clair. This change will restore the market equilibrium, incentivizing builders to purchase properties from low density housing homeowners for redevelopment and rapidly increasing the housing supply. Without another penny from taxpayers, this process will naturally increase housing supply by 1000% in key areas within 2 years as single detached units transition to higher density housing.</p>

<p className='blue-text-800'>Rejuvenate Toronto&rsquo;s Economic Growth Through Housing</p>

<p>Toronto&rsquo;s economic growth has stagnated under John Tory. His socialist housing policies have excluded global talent from enriching Toronto&rsquo;s socioeconomic landscape by artificially restricting the housing supply. <a className='article-link' target='_blank' href="https://chicagounbound.uchicago.edu/cgi/viewcontent.cgi?referer=&httpsredir=1&article=1045&context=housing_law_and_policy" rel="noopener noreferrer">Leading scientific studies and sound economics</a> have shown that even a small decrease in zoning regulation will significantly increase productivity and economic growth. Jack&rsquo;s housing policy will prioritize Toronto&rsquo;s economic health, create jobs, and level the playing field for disadvantaged talents.</p>

<p>Jack&rsquo;s housing policy will immediately create more jobs during the initial transition period to higher density units and the establishment of storefronts in mixed-use zones will permanently increase economic activity in the neighbourhood. The combination of increased housing supply as the result of higher density units and a much more vibrant social landscape thanks to increased commercial activity will catapult Toronto into a different league on the world stage on par with New York and London, and will attract young global talent to further its economic growth. This influx of talent and increased economic activity will significantly increase the city&rsquo;s tax revenue, allowing for lower property taxes. <Link className='article-link' to='/government-waste'>Understand the math behind how Jack's policies could lead to a 50% decrease in your property tax bill.</Link></p>

<p className='blue-text-800'>Affordable Housing</p>

<p>Tory has wasted hundreds of millions of your tax dollars on Toronto&rsquo;s unfair and ineffective affordable housing program and has recently introduced new laws that threaten to cram token affordable housing units down the throats of all Torontonians. As part of Jack&rsquo;s effort to modernize Toronto&rsquo;s affordable housing program, he will immediately reverse Tory&rsquo;s newly introduced &ldquo;inclusionary&rdquo; zoning policy and replace the lottery system with a more efficient and meritocratic system.</p>

<p><a className='article-link' rel="noopener noreferrer" href="https://www.thestar.com/opinion/contributors/2021/11/25/inclusionary-zoning-is-more-likely-to-kill-rather-than-create-the-construction-of-much-needed-housing.html" target="_blank" rel="noopener noreferrer">Inclusionary Zoning</a> is a set of highly misleading bylaws that provides a few affordable housing units for Tory&rsquo;s political allies at the expense of the average Torontonian. Inclusionary Zoning directly inflates the cost of dwellings for the average Torontonian by increasing the cost of development for builders and thus increases the cost of homeownership and rental for everyone <i>except</i> for those &ldquo;included&rdquo; in Tory&rsquo;s &ldquo;inclusionary&rdquo; zoning policy. By reversing Inclusionary Zoning, Jack will allow the market to naturally increase housing supply and comparatively lower housing costs for the benefit of all Torontonians, not just the lucky few who won the lottery.</p>

<p>The existing lottery system is not only unfair, Tory&rsquo;s affordable housing policy is rife with administration failures where <a className='article-link' target="_blank" href="https://toronto.ctvnews.ca/why-are-high-income-earners-living-in-low-income-housing-1.2373001" rel="noopener noreferrer">high income households regularly take up spots in the program.</a> Toronto should not be in the business of handing out token affordable housing units to any group of people at the expense of everyone else. Jack&rsquo;s affordable housing policy will instead serve to enhance Toronto&rsquo;s economy by ensuring that high potential tenants get the temporary boost that they need to stay in Toronto. Jack will introduce a $100 million housing fund to cover the cost of rent up to $30,000 for one year for highly ambitious individuals seeking to establish residence in Toronto; such as students studying to obtain professional licences (e.g. doctors, dentists), culinary professionals looking to push the boundary of Toronto&rsquo;s dining scene, or an up and coming artist with a ground breaking portfolio. Jack&rsquo;s affordable housing program will eliminate the unfair lottery system and ensure that only those who can meaningfully contribute to Toronto&rsquo;s economy will receive assistance provided by the city. </p>		
		</div>
	)
}
