import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import './EmailUnsubSpecific.css'
import { Button } from 'antd';
import axios from 'axios'
import queryString from 'query-string'
import { CheckOutlined } from '@ant-design/icons';

//requires query params: /email-unsubscribe?category=....&unsubKey=....

const UnsubSpecific = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const navigate = useNavigate();
    //category = one of ['profilePost', 'comment', 'features']
    const { category, unsubKey } = queryString.parse(useLocation().search);
    const [status, setStatus] = useState(null); //null, loading, done

    const getUserSubscriptions = async () => {
        await axios.post('/api/all/info/getEmailSubs', { unsubKey: unsubKey })
            .then(res => {
                setSubscriptions(res.data.emailCategories)
                if (!res.data.emailCategories.includes(category)) setStatus("done")
            })
            .catch(err => console.log(err.response))
    }

    useEffect(() => {
        document.title = "Email Preference | Jack Yan for Mayor of Toronto: Official Campaign Website"
        getUserSubscriptions();
    }, [])



    const handleUnsub = async () => {
        setStatus("loading")
        let newSubs = subscriptions
        newSubs = newSubs.filter(item => {
            return item !== category
        })
        axios.post('/api/all/info/updateEmailSubs', { emailCategories: newSubs, unsubKey: unsubKey })
            .then((res) => {
                setStatus("done")
                setTimeout(() => {
                    setStatus(null)
                }, 3000)
            })
    }

    const categoryString = (category) => {
        switch (category) {
            case "campaign":
                return 'Campaign News'
                break;
            case "volunteer":
                return 'Volunteer Requests'
                break;
            default: 
                return null
        }
    }

    // console.log(subscriptions)
    return (
        <div className="unsubSpecific">

            <div className="unsubMessage">
                <div>Are you sure you want to unsubscribe from <span className="unsubCategory">{categoryString(category)}</span> ?</div>
                <div style={{ margin: 10 }}>
                    <span className="unsubBtnContainer">
                        <Button loading={status === "loading"} className="unsubBtn" onClick={handleUnsub}>Unsubscribe</Button>
                    </span>
                    <span className='unsubCheckmark' style={{ visibility: status === 'done' ? '' : 'hidden' }}>
                        <CheckOutlined style={{ color: "green", paddingLeft: "8px" }} />
                    </span>
                </div>
                {status === 'done' && <div>Unsubscribe Successful</div>}
            </div>
        </div>
    )
}

export default UnsubSpecific
