import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./Issues.css"

export default function IssuesReduceGovWaste(props){
	return(
		<div className="issues-article-body">
<p>John Tory has wasted billions of your tax dollars on ineffective socialist programs without addressing any of the core problems. This waste will continue to be a drag on Toronto&rsquo;s economy as long as his policies encourage people to take advantage of the city's handouts. Jack will eliminate government waste and introduce sound policies to provide free transportation to Torontonians, decrease crime, increase housing supply, and quickly revitalize Toronto&rsquo;s waterfront for the benefit of all Torontonians.</p>

<br/>
<p style={{fontSize:"calc(20px + 4 * ((100vw - 375px) / 1065))"}}className='blue-text-800'>OPERATING BUDGET</p>

<p className='blue-text-800'>Reduce SSHA costs +$759 million</p>

<p>The city will reduce reliance on inefficient shelters and homeless administrators. <Link className="article-link" to="/homelessness">Read about Jack&rsquo;s policy on the homelessness crisis.</Link>
</p>

<p className='blue-text-800'>Reduce Waterfront Initiative overhead +$13 million</p>

<p>The city will disband the dysfunctional Waterfront Toronto
 and replace it with a lean team of ten professionals with a budget
  of $1 million. Responsibilities will be focused on soliciting and
   evaluating development proposals with guarantee of public access. <Link className="article-link" to="/waterfront">Read about Jack&rsquo;s plan to revitalize Toronto's waterfront.</Link></p>

<p className='blue-text-800'>Remove funding for uneconomical recreational events +$52 million</p>

<p>Poorly run recreational events that are not economical (i.e. unsupported by the community through ticket sales or donations) will no longer be supported.</p>

<p className='blue-text-800'>TTC - Free Ride For All Phase 1 -$575 million</p>

<p>This accounts for the immediate ~90% reduction of the price of a monthly TTC pass from $156 to $20, saving many Torontonians thousands of dollars a year. <Link className="article-link" to="/free-ttc">Read about Jack&rsquo;s plan to provide free unlimited TTC rides to Torontonians.</Link></p>

<p className='blue-text-800'>TPS - Increase budget for additional policing resources in high-crime areas, the formation of the special task force, and the introduction of performance bonuses -$200 million</p>

<p>Funds will be used to train officers, establish a special task force for female and minority victims, introduce performance bonuses, and increase general policing resources to effectively monitor high crime areas. <Link className="article-link" to="/crime">Read about Jack&rsquo;s plan to stamp out crime.</Link></p>

<p className='blue-text-800'>Long-term expected property tax revenue from increased housing supply +$2.5 billion</p> 

<p>This figure assumes 20-25% of low density zones are redeveloped into higher density housing (6-8 stories) within five years, with new units assessed at one third of the value of the original low density unit. This significant tax receipt accounts for over 50% of Toronto's expected property tax revenue in 2022, thus providing the city with the option of property tax reductions in the coming years. <Link className="article-link" to="/housing">Read about Jack&rsquo;s housing policy.</Link></p>

<br/>
<p style={{fontSize:"calc(20px + 4 * ((100vw - 375px) / 1065))"}}className='blue-text-800'>Capital Budget</p>

<p className='blue-text-800'>Reduce Affordable Housing Development +$64 million</p>

<p>Jack will reduce affordable housing development&rsquo;s capital budget from $164 million to $100 million, which will be solely used to fund temporary housing for high potential tenants. <Link className="article-link" to="/housing">Read about Jack&rsquo;s housing policy.</Link></p>

<p className='blue-text-800'>Pause Tory&rsquo;s Stagnating Waterfront Initiative +$98 million</p>

<p>Development will be paused pending evaluation of privatization proposals with public access. <Link className="article-link" to="/waterfront">Read about Jack&rsquo;s plan to revitalize Toronto's waterfront.</Link></p>

<p className='blue-text-800'>TTC Ticketing Overhaul - $100 million</p>

<p>This will cover the cost of RFID equipment and software development. <Link className="article-link" to="/free-ttc">Read about Jack&rsquo;s plan to provide a better riding experience on the TTC.</Link></p>		
		
<br/>
<p style={{fontSize:"calc(20px + 4 * ((100vw - 375px) / 1065))"}} className='blue-text-800'>Summary</p>

<p>Jack&rsquo;s policies will produce of $49 million of operating surplus in the short-term for Toronto, decrease crime, and put thousands of dollars back into the hands of many Torontonians. Expected redevelopment of low density residential zones will produce an additional $2.5 billion of property tax revenue within 5 years, leading to property tax cuts in the 50% range.</p>

<p>Jack's plan to modernize the TTC, increase assistance to high potential tenants, and rapidly develop Toronto's Waterfront will not expand Toronto's capital needs, in fact, cashflows will increase by $62 million after shutting off ineffective government programs. This additional cash will reduce Toronto's need for debt and could be contributed towards other high importance projects such as road condition improvements.</p>
		</div>
	)
}
