import React, { useEffect, useRef, useState, useContext } from 'react';
import { Row, Col, Drawer, Button, Menu } from 'antd';
import {Helmet} from "react-helmet";
import useScreenSize from 'use-screen-size'
import axios from "axios";
import { useCookies } from 'react-cookie';
import './Donation.css'
import { TagContext } from '../../App.js'

const { GA4PUBKEY } = require('../../constants.json')

export const DonationButton = (props) => {

	const [cookies, setCookie] = useCookies(['_fbp','_fbc'])

	const { gtagActive } = useContext(TagContext)

	const handleClick = (mode) => {
		if(gtagActive){
			window.gtag("event","donation_attempted")
			window.gtag("get", GA4PUBKEY, "client_id", clientId => {
				if(mode == 'custom'){
					axios.post('/api/all/create-checkout-session', {GAclientID: clientId, pathname: window.location.pathname, fbc: cookies._fbc ? cookies._fbc : null, fbp: cookies._fbp ? cookies._fbp : null})
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))		
				} else {
					axios.post('/api/all/create-checkout-session',{amount: mode, GAclientID: clientId, pathname: window.location.pathname, fbc: cookies._fbc ? cookies._fbc : null, fbp: cookies._fbp ? cookies._fbp : null})
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))	
				}
			})
		} else {
				if(mode == 'custom'){
					axios.post('/api/all/create-checkout-session',)
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))		
				} else {
					axios.post('/api/all/create-checkout-session',{amount: mode})
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))	
				}
		}
	}

	const propClass = props.cssClass ? props.cssClass : "donateButtons"

	return(
		<Button style={{height:"auto"}}shape="round" className={propClass} onClick={() => handleClick(props.amount)}><div className={props.cssClass}><div>{props.text}</div></div></Button>
	)
}	

const Donation = (props) => {


	const separatorDonationArr1 = [
		{amount: "15", text:"$15"},
		{amount: "38", text:"$38"},
		{amount: "50", text:"$50"},
		{amount: "100", text:"$100"},
	]

	const separatorDonationArr2 = [
		{amount: "150", text:"$150"},
		{amount: "250", text:"$250"},
		{amount: "500", text:"$500"},
		{amount: "custom", text:"Other"},
	]

	if(props.mobile){
		return(
		<Row>
			<Row style={{margin:"1em", padding:"0em"}}>

				<Row style={{padding: "1em", width:"100%"}}>
					<Col className="donate-separator-left" span={24}>

						<div>
							CONTRIBUTE TOWARDS A BETTER TORONTO
						</div>						
					</Col>
				</Row>
				<Row justify="center" style={{width:"100%"}}>
				<Col style={{alignSelf:"center"}}span={24}>
					<Row style={{margin:"1em"}} justify="space-around">
						{separatorDonationArr1.map(x => {
							return <DonationButton key={x.amount} amount={x.amount} text={x.text} />
						})}
					</Row>
					<Row style={{margin:"1em"}} justify="space-around">
						{separatorDonationArr2.map(x => {
							return <DonationButton key={x.amount} amount={x.amount} text={x.text} />
						})}					
					</Row>
				</Col>
				</Row>							
			</Row>
			<Row justify="center" style={{color:"white", width:"100%", position:"absolute", "bottom": "1em", fontSize:"0.5em"}}>
				<Col>Payment processing by Stripe, operated by Keyp Technologies</Col>
			</Row>	
		</Row>			
		)
	}	

	if(props.separator){
		return(
			<Row style={{margin:"1em", padding:"0em"}}>
				<Col className="donate-separator-left" span={10}>
					<Row style={{padding: "1em", width:"100%"}}>
						<div>
							CONTRIBUTE TOWARDS A BETTER TORONTO
						</div>
					</Row>
					<div style={{textAlign:"center", width:"100%", position:"absolute", "bottom": "1em", fontSize:"0.3em"}}>
						Payment processing by Stripe, operated by Keyp Technologies
					</div>					
				</Col>
				<Col style={{alignSelf:"center"}}span={14}>
					<Row style={{margin:"1em"}} justify="space-around">
						{separatorDonationArr1.map(x => {
							return <DonationButton key={x.amount} amount={x.amount} text={x.text} />
						})}
					</Row>
					<Row style={{margin:"1em"}} justify="space-around">
						{separatorDonationArr2.map(x => {
							return <DonationButton key={x.amount} amount={x.amount} text={x.text} />
						})}					
					</Row>
				</Col>
			</Row>
			)
	} else {
	return(
null
	)		
	}


}

export default Donation
