import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./Issues.css"

export default function IssuesWaterfront(props){
	return(
		<div className="issues-article-body">
<p>The current state of Toronto&rsquo;s Waterfront is pitiful by any international standard. Tory has squandered hundreds of millions of funding and billions of economic capital. Under Jack&rsquo;s leadership, the city will work quickly to develop Toronto&rsquo;s Waterfront for all Torontonians to enjoy. He will:</p>

<ul>
<li>Decrease development red tape </li>
<li>Immediately solicit private development proposals with public access</li>
</ul>

<p className='blue-text-800'>Decrease Development Red Tape</p>

<p>Jack will disband the dysfunctional Waterfront Toronto to expedite development along the waters. For over twenty years the development of Toronto&rsquo;s prized waterfront land has been mired in a bureaucratic nightmare. The organization has been busy evaluating irrelevant issues such as affordable housing and the opinion of special interest groups. Jack will immediately end all such discussions and ensure that no special interest group obtains any priority access to Toronto&rsquo;s waterfront, because Toronto&rsquo;s waterfront will be open to everybody. The only level access that will be guaranteed by the city is public access.</p>

<p className='blue-text-800'>Immediately Solicit Private Development Proposals with Public Access</p>

<p>Limited government staff and layers of red tape can never compete with Toronto's hard working entrepreneurs. Jack will immediately open up waterfront land to private development on the condition that there will be an unrestricted pedestrian boulevard along the entirety of Toronto&rsquo;s waterfront. In five years time, Toronto&rsquo;s waterfront will be teeming with small businesses and a beautiful, uninterrupted pedestrian walkway will stretch from Marie Curtis Park to Rouge Beach. Torontonians can expect significantly more recreational and entertainment options along the waters and the city will generate revenue from this revitalization to service the newly built public boulevards without raising taxes.</p>
		</div>
	)
}
