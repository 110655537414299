import {Helmet} from "react-helmet";
export default function PrivacyPolicy(props){

return(
    <div style={{textAlign:"left", padding:"1em"}}>
            <Helmet>
                <title>Privacy Policy | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>      
  <p><h1>Privacy Policy</h1></p>
<p>The Jack Yan Mayoral Campaign (“Campaign”) is committed to respecting your privacy. The purpose of this privacy policy is to explain how we handle personal information to ensure its confidentiality, security and accuracy.
Our privacy policy can always be publicly viewed at jack2022.ca/privacy.</p>

<p><h1>WHAT IS “PERSONAL INFORMATION”?</h1></p>

<p>“Personal information” is information about an identifiable individual. It includes contact information such as your name, address, phone number and financial information.
</p>
<p>
<h1>HOW DO WE OBTAIN YOUR PERSONAL INFORMATION AND WHAT DO WE OBTAIN?</h1></p>
<p>
We obtain personal information in a few different ways.
<br/>
We may also collect information from publicly available data sources, and through voter outreach activities, such as door-to-door canvassing, voter contact phone calls, and text messages.
<br/>
We also obtain other information that you choose to give us. You may do so in a variety of ways, including:
<ul>
    <li>When you visit our website for the purpose of becoming involved with the Campaign as volunteer or donor.</li>
    <li>When you subscribe to our communications, such as email updates, newsletters or text message alerts.</li>
    <li>If you register at an event</li>
    <li>If you complete a registration or donation form either electronically or on paper.</li> 
    <li>If you complete any other form on a Campaign website, including online petitions.</li>
    <li>It is also possible that your information could be provided to us by a volunteer or friend who thinks you would be interested in getting involved with the Campaign.</li> 
</ul>
<br/>
The information we collect may include:
<ul>
    <li>Contact and identification information, such as your name, address, telephone numbers, e-mail address and social media contacts.</li> 
    <li>Donation information such as date and amount of your donation.</li> 
    <li>Financial information that we need to process your donation e.g. payment methods and preferences, billing and banking information (e.g. credit card number and expiry date).</li> 
    <li>Information you share about your voting preferences or issues that you have communicated to us about. </li>
</ul>
</p>
<p>
<h1>HOW DO WE USE AND SHARE PERSONAL INFORMATION?</h1></p>
<p>
We use your personal information to communicate with you about the Campaign and its activities, as well as to provide you with news and information. We use your financial information to process your contributions. If you have been a contributor in the past, we may contact you again to seek your financial support.
<br/>We will not, without your consent, use your personal information for any purpose other than as described in this privacy policy, except where permitted or required by applicable legislation. This could also include when we believe in good faith that we are lawfully authorized or required to do so to protect the safety of our team or the public.
<br/>We may engage third party providers to perform tasks on our behalf, such as processing your donation, making phone calls, providing data hosting, mailing, or analytics services and providing technical services to our website. 
<br/>We do not sell personal information under any circumstance.
</p>
<p>
<h1>HOW DO WE PROTECT PERSONAL INFORMATION?</h1></p>
<p>
The security of your personal information is important to us.<br/>
We use physical, electronic and procedural safeguards to prevent unauthorized use, sharing, loss and theft of information.
<br/>
Our security practices are continually evolving to ensure the integrity and confidentiality of our systems and information in our possession. We use up-to-date security safeguards to protect our networks and websites.
<br/>
For example, for information provided online, our website contains security measures in order to protect against the unauthorized disclosure, loss, misuse, or alteration of the information under our control. We also implement measures such as SSL encryption on sensitive information including passwords and credit card information. 
</p>
<p><h1>HOW CAN I UPDATE MY INFORMATION OR UNSUBSCRIBE?</h1></p>
<p>You may update or correct the information in our possession by e-mailing us at voters@jack2022.ca.
<br/>If you have subscribed to receive information by email, you may unsubscribe by clicking “Unsubscribe” at the bottom of the email message.
</p>
<p><h1>WHAT ABOUT ANTI-SPAM LEGISLATION?</h1></p>
<p>
Most of the messages sent by the Campaign are exempt from the application of Canada’s anti-spam law which applies to commercial electronic messages sent to electronic addresses. This is because the electronic messages that we send are generally either those soliciting donations, which are specifically exempt under the law, or are messages of a political, not a commercial, character. If we do send any messages to which the law applies and for which there are no exemptions, we will ensure that we have consent to do so, as required by the law, and that any other legal requirements e.g. an unsubscribe mechanism, are met.
<br/>
As a best practice, we have an unsubscribe mechanism for our electronic messages, even where the law does not require us to do so.
</p>
<p><h1>DOES THE CAMPAIGN LOG IP ADDRESSES?</h1></p>

<p>
We and/or our network service providers may log IP addresses, or the location of your device, including browser type, browser language, and device time zone, for systems administration, content optimization, and troubleshooting purposes. We may also use IP addresses to track which pages people visit in order to improve the quality of our website and other online engagement.
</p>
<p><h1>DOES THE CAMPAIGN USE COOKIES?</h1></p>

Like many websites, we use cookies which are small text files stored on the user’s browser. We use cookies to, for example, assist with site traffic analysis which includes tracking the time and date of website visits, pages viewed and referring URL’s. There are simple ways to refuse cookies, or accept them each time they are offered. Consult your browser’s help files for assistance on changing cookie settings or removing cookie files.

<p><h1>LINKS TO OTHER WEBSITES</h1></p>

<p>
Our website contains links to a limited number of other websites. The Campaign is not responsible for the content or the privacy policies of these websites.
</p> 
  </div>
  )
}
