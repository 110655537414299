import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import useScreenSize from 'use-screen-size'
import TextTransition, { presets } from "react-text-transition";
import {Helmet} from "react-helmet";
import arrowWhite from './arrow-white.svg'
import "./Issues.css"


const { Panel } = Collapse;

const Issues = props => {

const size = useScreenSize()

const Fader = () => {

	const text=[
				"REDUCE GOVERNMENT WASTE",
				"SOLVE THE HOUSING CRISIS", 
				"REDUCE CRIME", 
				"CREATE MORE JOBS",
				"REMOVE RED TAPE",
				"IMPROVE TRANSIT",
				"INCREASE DIVERSITY",
				"REDUCE GOVERNMENT WASTE",
				"INCREASE QUALITY OF LIFE",
				]

	const [index, setIndex] = useState(0);

	// useEffect(() => {
	//     const intervalId = setInterval(() => {
	// 		    setIndex(index => {
	// 		    	if(index == text.length -1 ){
	// 		    		return 0
	// 		    	} else {
	// 		    		return index + 1
	// 		    	}
	// 		    })
	//   		},
	//       4000 // every 3 seconds
	//     );
	//     return () => clearTimeout(intervalId);
	//   }, []);


	const [fadeProp, setFadeProp] = useState({fade: "fade-in"})

	useEffect(() => {
	     const timeout = setInterval(() => {
	        if (fadeProp.fade === 'fade-in') {
	           setFadeProp({
	                fade: 'fade-out'
	           })
	        } else {	        	
	             setFadeProp({
	                fade: 'fade-in'
	             })
				    setIndex(index => {
				    	if(index == text.length -1 ){
				    		return 0
				    	} else {
				    		return index + 1
				    	}})
	        }
	     }, 2000);return () => clearInterval(timeout)
	}, [fadeProp])

		return (
		    <>
		      <div className={fadeProp.fade + " " + "blue-text-800"} style={{padding:"0 2em 0 2em",fontSize:"calc(33px + 17 * ((100vw - 375px) / 1065))"}}>{text[index]}</div>
		    </>
		)
	}


	const text = `
	  A dog is a type of domesticated animal.
	  Known for its loyalty and faithfulness,
	  it can be found as a welcome guest in many households across the world.
	`;

					  // <Collapse defaultActiveKey={['1']} ghost>
					  //   <Panel header="Homelessness" key="1">
					  //     <p>{text}</p>
					  //   </Panel>
					  //   <Panel header="Transit" key="2">
					  //     <p>{text}</p>
					  //   </Panel>
					  //   <Panel header="SmartTrack" key="3">
					  //     <p>{text}</p>
					  //   </Panel>
					  //   <Panel header="Infrastructure Spending" key="4">
					  //     <p>{text}</p>
					  //   </Panel>
					  //   <Panel header="Housing" key="5">
					  //     <p>{text}</p>
					  //   </Panel>
					  //   <Panel header="Crime" key="6">
					  //     <p>{text}</p>
					  //   </Panel>	    	    	    
					  // </Collapse>	
	const BigCard = (props) => {

		const {line1, line2, line3, linkTo} = props.config

		return(
			<div className='issues-big-card'>
				<div className="issues-big-card-inner-wrapper">
					<Row className='big-card-line1'>
						{line1}
					</Row>
					<Row className='big-card-line2'>
						{line2}
					</Row>
					<Row className='big-card-line3'>
						{line3}
					</Row>
				</div>
				<div style={{position:"absolute", paddingLeft:"5%",bottom:"2rem"}}>
					<Link to={linkTo}><Button className="issues-read-more-btn">Read More</Button></Link>				
				</div>
			</div>
			)
	}

	const SmallCard = (props) => {

		const {line1, line2, cssOverRide, link} = props.config

		return(
			<div className='issues-small-card' style={props.mobile ? {} : cssOverRide}>
				<div className="issues-small-card-inner-wrapper">
					<Link to={link}>
						<Row justify="center" className='small-card-line1'>
								{line1}
						</Row>
						<Row justify="center" className='small-card-line2'>
								{line2}
						</Row>
					</Link>
				</div>
			</div>
			)
	}	

	const transportConfig = {
		line1: "JACK'S PLAN TO",
		line2: "Have Free TTC for all Torontonians",
		cssOverRide: {marginBottom:"20px"},
		link: "/free-ttc"
	}

	const twoConfig = {
		line1: "JACK'S PLAN TO",
		line2: "Combat Skyrocketing Violent Crimes",
		link: "/crime"
	}

	const threeConfig = {
		line1: "JACK'S PLAN TO",
		line2: "Revitalize Toronto's Waterfront",
		cssOverRide: {marginBottom:"20px"},
		link: "/waterfront"
	}

	const fourConfig = {
		line1: "JACK'S PLAN TO",
		line2: "Reduce Government Waste",
		link: "/government-waste"
	}			

	const housingConfig = {
		line1: "JACK'S PLAN TO",
		line2: "Solve the Housing Crisis", 
		line3: "Jack has a plan that will benefit homeowners and tenants, create jobs, increase cultural diversity, while generating significant property tax revenue for the city.",
		linkTo: "/housing"
	}

	const homelessConfig = {
		line1: "JACK'S PLAN TO",
		line2: "End the Homelessness Crisis",
		line3: "Toronto is on a path to become the San Francisco of Canada. Jack will put an end to the rapidly escalating homelessness problem and stop Tory's waste of your tax dollars.",
		linkTo: "/homelessness"
	}


	if(size.width < 992){
		return(
		<Row>
            <Helmet>
                <title>Jack's Vision | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>   		
				<Col span={24}>
						<Row style={{paddingTop:"1em", paddingBottom:"1em"}}justify='center'>
							<Col span={24} >
								<div style={{textAlign:"center"}}>
									<div style={{fontSize:"3em", fontStyle:'italic', color:"#1D2D5C"}} className='blue-text-800'>Jack Will</div>
									<Fader/>
							  </div>
							</Col>
					  </Row>
			  	<Row className="issues-row-wrapper">
			  		<Col span={24} >
							<BigCard config={housingConfig}/>										
					</Col>
				</Row>
			  	<Row className="issues-row-wrapper">
			  		<Col span={24} >
							<BigCard config={homelessConfig}/>
					</Col>
				</Row>	
			  	<Row className="issues-small-card-mobile" >
			  		<Col span={24} >
							<SmallCard mobile config={transportConfig}/>
					</Col>
				</Row>		
			  	<Row className="issues-small-card-mobile" >
			  		<Col span={24} >
							<SmallCard mobile config={twoConfig}/>
					</Col>
				</Row>	
			  	<Row className="issues-small-card-mobile">
			  		<Col span={24} >
							<SmallCard mobile config={threeConfig}/>
					</Col>
				</Row>	
			  	<Row className="issues-small-card-mobile">
			  		<Col span={24} >
							<SmallCard mobile config={fourConfig}/>
					</Col>
				</Row>																			
			</Col>
	  </Row>	
			)
	} else {

	return(
		<div>
            <Helmet>
                <title>Jack's Vision | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>   		
			<Row style={{paddingTop:"5em", paddingBottom:"5em"}}justify='center'>
				<Col span={24} >
					<div style={{textAlign:"center"}}>
						<div style={{fontSize:"3em", fontStyle:'italic', color:"#1D2D5C"}} className='blue-text-800'>Jack Will</div>
						<Fader/>
				  </div>
				</Col>
		  </Row>
		  <Row justify="center">
		  	<Row className="issues-row-wrapper">
		  		<Col span={16} >
						<BigCard config={housingConfig}/>
					</Col>
				<Col span={8} style={{paddingLeft:"25px"}} >
					<SmallCard config={transportConfig}/>
					<SmallCard config={twoConfig}/>
				</Col>
			</Row>
		  	<Row className="issues-row-wrapper">
		  		<Col span={16} >
						<BigCard config={homelessConfig}/>
					</Col>
				<Col span={8} style={{paddingLeft:"25px"}} >
					<SmallCard config={threeConfig}/>
					<SmallCard config={fourConfig}/ >
				</Col>
			</Row>		
			</Row>	
	  </div>
	)
	}
}

export default Issues