import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./Issues.css"

export default function IssuesCrime(props){
	return(
		<div className="issues-article-body">
<p>Toronto is spending billions of dollars on law enforcement, yet all major crime indicators have skyrocketed under Tory. Homicides are up 46%, violent assaults with a gun are up 41%, carjackings are up 76%, and shootings are up a whopping 131%. This alarming rise of crimes in Toronto is the symptom of a weak leader who appeases criminals instead of protecting Torontonians. In typical Tory fashion, he has been very &ldquo;concerned&rdquo; about violent assaults on women, private property, minorities, and the Toronto public, while being completely silent on a solution. Under Jack&rsquo;s leadership, the Toronto Police Service will see a cultural shift where stamping out crime is to become the top priority. Jack will:</p>

<ul>

<li>Increase police efficacy by linking reductions in crimes to performance bonuses</li>
<li>Active monitor and and significantly increase officer presence in high-crime areas</li>
<li>Establish special task force for female and minority victims</li>

</ul>

<p className='blue-text-800'>Increase Police Efficacy by Linking Reductions in Crimes to Performance Bonuses</p>

<p>A major responsibility of the Toronto Police Service is to reduce crime. Under Tory, the TPS has been whipped to be lenient on career criminals instead of unapologetically protecting Torontonians. Jack will reinvigorate our law enforcement officers by critically evaluating division performances on a quarterly basis. Performance bonuses will be paid based on the reduction of crime. Meetings will be held weekly to ensure that best practices can propagate quickly across all divisions. Underperformance, as indicated by anything but a decrease in crimes, will lead to dismissal on a quarterly basis, so as to guarantee that Torontonians will only be served by competent officers that have the ability to uphold the law.</p>

<p className='blue-text-800'>Actively Monitor and Significantly Increase Police Presence in High-Crime Areas</p>

<p>Jack will direct the TPS to increase officer presence in high-crime areas to deter crime and to decrease response time. Around the clock foot patrols will be carried out in such areas and officers will station vehicles at neighourhood choke points to decrease the chance of escape for violent criminals. Law enforcement officers will be encouraged to proactively investigate suspicious activities to discourage increasingly bold criminals from <a className='article-link' target='_blank' href="https://toronto.ctvnews.ca/two-ontario-teens-face-combined-100-charges-following-alleged-gunpoint-carjackings-1.5937960" rel="noopener noreferrer">carrying out violent attacks in broad daylight.</a></p>

<p className='blue-text-800'>Establish Special Task Force for Female and Minority Victims</p>

<p>Some of the biggest reasons why victims of hate crimes and sexual assaults are hesitant to file police reports are the red tapes in the criminal justice system and their lack of resources. The fact that dangerous criminals are walking away as free men due to administrative burden is an absolute travesty. To address these challenges, Jack will establish a dedicated task force serving minorities and female victims to ensure that perpetrators are brought to justice in accordance with the law. The task force will provide guidance to victims pursuing civil damages and will help them navigate the complex criminal justice system at no cost. Locating and arresting the perpetrators are to be among the TPS's top priorities and funding will be provided to encourage victims to take time off to assist with investigations.</p>
		</div>
	)
}
