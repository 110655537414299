import React, {useState, useEffect, useContext} from 'react';
import { TagContext } from '../../App.js'
import { Link, useParams} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Collapse, Button } from 'antd';
import {Helmet} from "react-helmet";
import "./Issues.css"


export default function FinalLetter(props){

	const dispatch = useDispatch()

	const { issues } = useParams()

	const { gtagActive } = useContext(TagContext)

	const twitterShare = () => {
		const text = `Why I am voting for Jack Yan for Mayor of Toronto. %23jack2022 %23jackyanformayor https://jack2022.ca/john-tory-fools-toronto-with-fake-conservatism`
		const newWindow = window.open(
			`https://twitter.com/intent/tweet?text=${text}`, 
			'_blank', 'noopener,noreferrer')
		if(gtagActive){
			window.gtag('event', 'share', {
				platform: 'twitter',
				issue: 'finalLetter'
			})
		}
	}

	const facebookShare = () => {
		const newWindow = window.open(
			`https://www.facebook.com/sharer/sharer.php?u=https://jack2022.ca/john-tory-fools-toronto-with-fake-conservatism`, 
			'_blank', 'noopener,noreferrer')
		if(gtagActive){
			window.gtag('event', 'share', {
				platform: 'facebook',
				issue: 'finalLetter'
			})
		}
	}

	return(
		<div style={{paddingBottom:"5em"}}>
            <Helmet>
                <title>John Tory Fools Toronto with Fake Conservatism | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>		
			<Row style={{paddingTop:"5em", paddingBottom:"5em"}}justify='center'>
				<Col span={24} >
					<div style={{textAlign:"center"}}>
						<div style={{fontSize:"3em", color:"#1D2D5C"}}>John Tory Fools Toronto with Fake Conservatism</div>
					  </div>
				</Col>
		  </Row>
		  <Row justify="center">
		  	<Col>
			  	<Button onClick={twitterShare} className="issues-share-btn">Share on Twitter</Button>
			  	<Button onClick={facebookShare} className="issues-share-btn">Share on Facebook</Button>
		  	</Col>
		  </Row>
		  <Row style={{placeContent:"center"}}>
			<div className="issues-article-body">
				<p>Torontonians, make no mistake. <a className='article-link' target="_blank" rel="noopener noreferrer"  href="https://www.cbc.ca/news/canada/toronto/anti-gun-violence-protest-2022-1.6594870">A wave of violent crimes</a>, <a className='article-link' target="_blank" rel="noopener noreferrer"  href="https://www.thestar.com/news/gta/2022/10/18/potholes-can-cause-hundreds-in-damage-to-your-car-so-why-has-toronto-delayed-fixing-them.html">failing infrastructure</a>, <a className='article-link' target="_blank" rel="noopener noreferrer"  href="https://jack2022.ca/homelessness">skyrocketing homelessness</a>, and deteriorating city services are not inevitable and <i>are not</i> the pains of a growing city.</p>
				<p>Since 2014, John Tory has deliberately put his own political interest ahead of his duty to you by wasting billions of dollars on token affordable housing projects and shelters for positive press without scrutinizing his own policies that would necessitate such developments in the first place.</p>
				<p>A key tenet of conservatism is that a "responsible government must be fiscally prudent and should be limited to those responsibilities which cannot be discharged reasonably by the individual or others."</p>
				<p>Simply put, it means that the government should not excessively tax its citizens and should limit its role to key public goods such as transportation, utilities, policing, and necessary safety nets such as EI. </p>
				<p>John Tory has fulfilled only the first half the Conservative ideal, by keeping taxes low; but he has neglected the second part of this equation by allowing critical city infrastructure to crumble and crime to proliferate. Skyrocketing crime, rampant homelessness, and social disorder are not caused by conservatism, but by Tory's brand of fake conservatism. </p>
				<p>It doesn't take a genius to keep property taxes low, but it takes a special kind of politician to waste billions of your tax dollars on ineffective social programs, embolden criminals at every opportunity by kowtowing to special interest groups that are demanding an ever larger share of the city's budget, while neglecting key city infrastructure to the detriment of everyday Torontonians, and still be perceived as a conservative. Tory has deceived you by cunningly wrapping his failed policies in a neat little package bearing a Thank You card with the message "I'm trying my best!"</p>
				<p>But perhaps the media knows best. The Toronto Star Editorial Board, in all its wisdom, has endorsed John Tory. When a leading progressive media outlet endorses the mayor, ask yourself, is he really a responsible conservative, or a pretender without a backbone, whose only redeeming quality is the simple task of keeping property taxes low?</p>
				<p>Tory's fake conservatism produces the worst outcome from both ends of the political spectrum: wanton government waste from the left, and sacrificing critical city infrastructure for lower taxes from the right. Tory has failed everybody for eight years, do not let him lie to you for another four. </p>
				<p>Torontonians are resilient, we work hard for a brighter future and for our families despite Tory's failings. On Monday October 24, elect a mayor who will reward your labour and contributions to the city, past, present, and future. Vote Jack Yan for Mayor of Toronto.</p>
			</div>
		  </Row>
		</div>
	)	
}
