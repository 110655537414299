import React, { useEffect, useRef, useState } from 'react';
import { Row, Drawer, Button, Menu, Col, Input } from 'antd';
import { Link } from 'react-router-dom'
import './Footer.css'

import logoReverse from '../Navbar/logo-reverse.png'

export default function Footer(props) {
    return (
    <Row justify='center' style={{paddingTop: "5em", backgroundColor:"#00001f", color:"white"}}>
        <Col span={14}>
            <div className='footer-left'>
                <Link className="footer-text" to='/jacks-vision'><p>Jack's Vision</p></Link>
                <Link className="footer-text" to='/meet-jack'><p>Jack's Story</p></Link>
                <Link className="footer-text" to='/volunteer'><p>Volunteer</p></Link>
                <Link className="footer-text" to='/donate'><p>Donate</p></Link>
                <Link className="footer-text" to='/privacy'><p>Privacy Policy</p></Link>
            </div>
        </Col>
        <Col span={10}>
            <Row justify="right">
                <div>
                    <Link to="/"><img className='footer-logo' src={logoReverse}/></Link>
                </div>
            </Row>
            <Row className="footer-email-row">
                    <span className='footer-email'>media@jack2022.ca<br/>voters@jack2022.ca</span> 
            </Row>                

        </Col>
    </Row>
    )

}