import React from 'react'
import {Row, Col} from 'antd'
import {Helmet} from "react-helmet";
import './SuccessDonation.css'
import group from './group.JPG'

export default function SuccessDonation(props){
	return(
		<Row justify="center">
            <Helmet>
                <title>Thank You | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet> 		
			<Row>
				<div className="thank-you-header blue-text-800" >
				<span style={{fontSize:"1.5em"}}>THANK YOU FOR YOUR CONTRIBUTION</span> <br/><br/> Your invaluable support will allow us to reach as many people as possible. <br/><br/> I look forward to having your support at the polls.
				</div>
			</Row>
			<Row justify="center">
				<Col xs={23} md={18} lg={12}>
					<img style={{width:"100%", marginBottom:"3em"}}src={group}/>
				</Col>
			</Row>		
		</Row>
		)
}