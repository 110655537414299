import React, {useState, useEffect} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./Issues.css"

export default function IssuesHomeless(props){
	return(
		<div className="issues-article-body">
<p>Homelessness plagues all Torontonians. Homelessness not only costs the city billions of dollars in support services, Torontonians also bear the costs of increased sanitation and policing needs, as well as having to endure a squalid cityscape. If left unchecked, Toronto will soon face the same fate that has befallen cities such as San Francisco and Sacramento, <a className='article-link' href="https://www.dailymail.co.uk/news/article-10491511/Homelessness-San-Francisco-bad-people-asked-homeless-person-spare-room.html" target="_blank" rel="noopener noreferrer" >where open drug use, dangerous psychotic outbursts, unsafe and dirty streets, and increased crime have become a way of life.</a></p>

<p>As the Mayor of Toronto, Jack will ensure that Torontonians can safely stroll with their families at their local parks on a Saturday evening without being harassed, without encountering illegal and dilapidated encampments, and without the sight of open drug use and weapons. He will:</p>

<ul>
<li>Rigorously enforce existing laws, including the Safe Streets Act, without exception </li>
<li>Introduce more efficient shelter, food, and medical programs</li> 
<li>Scrutinize fundings to homeless support services to ensure that funds are only allocated to programs that can effectively decrease homelessness</li>
<li>Create clean streets and safe parks for Torontonians</li></ul>

<p>Despite spending billions of dollars on homeless support programs over the past eight years, the number of homeless people has soared by 40%. Tory's policies have resulted in a small town's worth of unstable individuals freely roaming around Toronto, disrupting many ordinary citizens' daily lives. It is clear that the current policy of mindlessly handing out cash is not the solution. Not only has your tax dollars been wasted, the numbers clearly show that Tory has only made the problem worse.</p>

<p>Under Jack&rsquo;s leadership, the city will tackle the homeless problem with a two-pronged approach. Firstly, Jack will revamp the current &ldquo;wait-and-see&rdquo; approach to homelessness to one of active management. A law that is not enforced becomes a mere suggestion, and Toronto will no longer tolerate blatant violations of law and order. Jack will work with law enforcement officers to ensure that all existing laws and bylaws are strictly enforced without delay. There will be a 24/7 hotline for all Torontonians to report illegal encampments as well as a dedicated task force to swiftly respond to incidents. This proactive approach will significantly cut down the cost of encampment clearing, decrease sanitation needs, as well as eliminating unnecessary and dangerous confrontations. Torontonians can expect clean streets and parks where they can safely stroll at all hours of the day and night.</p>

<p>Secondly, the city will streamline SSHA funding with the explicit objective of lowering homelessness and will centralize homeless support operations to increase efficiency. The city will rigorously evaluate funds directed towards NGOs, NPOs, charities, and all other entities and programs operating under the homelessness support umbrella, with the goal of reducing, not perpetuating Toronto's homeless problem. Entities that directly provide encampment materials such as tents will be banned from receiving city funding. Non-government shelter and food programs will be benchmarked against city run programs and only those that can provide the same services at a lower cost will be allocated funding. Shelters and medical facilities will be centralized in a location away from residential areas to alleviate safety concerns, to increase support program efficiency, and to ensure that rehabilitation can occur in a controlled environment.</p>
		</div>
	)
}
