import React,{useState, useRef} from 'react';
import { Row, Col } from 'antd';
import {Helmet} from "react-helmet";
import MeetPic from './blueshirt.JPG'
import './MeetJack.css'

const MeetJack = props =>{
	return(
		<div>
            <Helmet>
                <title>Meet Jack | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>   			
			<Row justify="center" className='meet-jack-mobile-img'>
				<img src={MeetPic} style={{width:"100%"}}/>
			</Row>
			<Row>
				<Col xl={12} lg={24}>
					<div className="meet-jack-left">
						<div className="meet-jack-left-text">SMALL BUSINESS SUPPORTER</div>
						<div className="meet-jack-left-text">FREE MARKET PROPONENT</div>
						<div className="meet-jack-left-text">ENTREPRENEUR</div>
						<div className="meet-jack-left-text">FINANCE AND TECHNOLOGY EXPERT</div>
					</div>
				<div className='meet-jack-text'>
					<p>
					Jack worked in the financial industry for several 
					years after graduating from Ivey Business School and became one
					of the world's youngest professionals to obtain the prestigious
					Chartered Financial Analyst designation. </p>

					<p>
					Currently Jack oversees the development of innovative consumer facing
					web platforms at his own firms in the technology and finance space
					and has 
					hired and mentored dozens of aspiring undergraduate students 
					across Canada.
					</p>
					<p>
					Jack is a staunch supporter of small businesses and 
					the hard working people of Toronto. As the son of first 
					generation immigrants, he has witnessed first hand the 
					value that small businesses bring to their communities and 
					their importance in supporting Toronto's vibrant cultural landscape. 
					</p>
					<p>
					Jack has travelled extensively across Europe, North America, 
					and Asia and wishes to bring the best around the world to Toronto. 
					</p>
				</div>					
				</Col>
				<Col className='meet-jack-right' xl={12} lg={0}>
					<img src={MeetPic} style={{width:"100%"}}/>
				</Col>			
			</Row>
		</div>
	)
}

export default MeetJack;

