import React, {useState, useEffect, useContext} from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { Link, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import "./Issues.css"
import IssuesHomeless from './IssuesHomeless'
import IssuesHousing from './IssuesHousing'
import IssuesCrime from './IssuesCrime'
import IssuesWaterfront from './IssuesWaterfront'
import IssuesTTC from './IssuesTTC'
import IssuesReduceGovWaste from './IssuesReduceGovWaste'
import { TagContext } from '../../App.js'
import { useCookies } from 'react-cookie';
import FullPageBanner from '../Banners/FullPageBanner'
import { selectAdState, selectPopup, setPopup } from '../../redux/features/adSlice'
import { useSelector, useDispatch } from 'react-redux'
import useScreenSize from 'use-screen-size'


export default function IssuesText(props){

	const adState = useSelector(selectAdState);
	const shouldShowPopup = useSelector(selectPopup)
	const dispatch = useDispatch()
const [cookies, setCookie] = useCookies(["lastShared"])
	const size = useScreenSize()

	const { issues } = useParams()
	const [page, setPage] = useState()

	const { gtagActive } = useContext(TagContext)

	const [delayCount, setDelayCount] = useState()

	const [popupVisible, setPopupVisible] = useState(false)
	useEffect(() => {
		if(shouldShowPopup){
			dispatch(setPopup(false))
			const delayArr = [10000, 15000, 20000]
			const delay = delayArr[Math.floor(Math.random()*delayArr.length)];
			setDelayCount(delay)
				const timeout = setTimeout(() => {
			      setPopupVisible(true)
			    }, delay)
			return () => clearTimeout(timeout) 
		} 
    }, [])

	const fetchText = () => {
		switch(issues) {
			case 'homelessness':
				return <IssuesHomeless/>
				break;
			case 'housing':
				return <IssuesHousing/>
				break;
			case 'crime':
				return <IssuesCrime/>
				break;
			case 'waterfront':
				return <IssuesWaterfront/>
				break;	
			case 'free-ttc':
				return <IssuesTTC/>
				break;	
			case 'government-waste':
				return <IssuesReduceGovWaste/>
				break;													
			default: 
				return null				
		} 		
	}

	const displayTitle = (title) => {

		switch(issues) {
			case 'homelessness':
				return 'ENDING THE HOMELESSNESS CRISIS'
				break;
			case 'housing':
				return 'SOLVING THE HOUSING CRISIS'
				break;
			case 'crime':
				return 'COMBATTING CRIME'
				break;
			case 'waterfront':
				return 'WATERFRONT REVITALIZATION'
				break;
			case 'free-ttc':
				return 'FREE TRANSIT FOR ALL'
				break;	
			case 'government-waste':
				return 'REDUCE GOVERNMENT WASTE'
				break;													
			default: 
				return null				
		} 				

		// return title.replace(/[\W_]+/g," ").toUpperCase()
	}

	const displayPageTitle = (title) => {

		switch(issues) {
			case 'homelessness':
				return 'Ending the Homelessness Crisis' 
				break;
			case 'housing':
				return 'Solving the Housing Crisis'
				break;
			case 'crime':
				return 'Combatting Crime'
				break;
			case 'waterfront':
				return "Revitalize Toronto's Waterfront"
				break;
			case 'free-ttc':
				return 'Free Transit for All'
				break;	
			case 'government-waste':
				return 'Reduce Government Waste'
				break;													
			default: 
				return null				
		} 				
	}	

	const twitterShare = () => {
		const text = `Why I am voting for Jack Yan for Mayor of Toronto. %23jack2022 %23jackyanformayor https://jack2022.ca/${issues}`
		const newWindow = window.open(
			`https://twitter.com/intent/tweet?text=${text}`, 
			'_blank', 'noopener,noreferrer')
		if(gtagActive){
			window.gtag('event', 'share', {
				platform: 'twitter',
				issue: issues
			})
		}
		setCookie('lastShared', Date.now()/1000)
	}

	const facebookShare = () => {
		const newWindow = window.open(
			`https://www.facebook.com/sharer/sharer.php?u=https://jack2022.ca/${issues}`, 
			'_blank', 'noopener,noreferrer')
		if(gtagActive){
			window.gtag('event', 'share', {
				platform: 'facebook',
				issue: issues
			})
		}
		setCookie('lastShared', Date.now()/1000)
	}

	const getStyle = issues => {
		if(size.width <  501) {
		switch(issues) {
			case 'homelessness':
				return {fontSize:"3em", color:"#1D2D5C"}
				break;
			case 'housing':
				return {fontSize:"5em", color:"#1D2D5C"}
				break;
			case 'crime':
				return {fontSize:"3em", color:"#1D2D5C"}
				break;
			case 'waterfront':
				return {fontSize:"3em", color:"#1D2D5C"}
				break;
			case 'free-ttc':
				return {fontSize:"5em", color:"#1D2D5C"}
				break;	
			case 'government-waste':
				return {fontSize:"3em", color:"#1D2D5C"}
				break;													
			default: 
				return {fontSize:"5em", color:"#1D2D5C"}			
		} 	
		} else {
			return {fontSize:"5em", color:"#1D2D5C"}
		}

	}

	return(
		<div style={{paddingBottom:"5em"}}>
            <Helmet>
                <title>{`${displayPageTitle()}` + " | Jack Yan for Mayor of Toronto: Official Campaign Website"}</title>
            </Helmet>		
			<Row style={{paddingTop:"5em", paddingBottom:"5em"}}justify='center'>
				<Col span={24} >
					<div style={{textAlign:"center"}}>
						<div style={getStyle(issues)}>{displayTitle(issues)}</div>
					  </div>
				</Col>
		  </Row>
		  <Row justify="center">
		  	<Col>
			  	<Button onClick={twitterShare} className="issues-share-btn">Share on Twitter</Button>
			  	<Button onClick={facebookShare} className="issues-share-btn">Share on Facebook</Button>
		  	</Col>
		  </Row>
		  <Row style={{placeContent:"center"}}>
		  	{/*<Col span={24}>*/}
		  		{fetchText()}
		  	{/*</Col>*/}
		  </Row>
		</div>
	)
}
