import React, { useEffect, useRef, useState, useContext } from 'react';
import { Row, Drawer, Button, Menu, Col, Input } from 'antd';
import useScreenSize from 'use-screen-size'
import {useNavigate, Link} from 'react-router-dom'
import axios from 'axios'
import redwalk from '../LandingPage/redwalk.JPG'
import suitRed from './suit-red.JPG'
import { TagContext } from '../../App.js'
import { useCookies } from 'react-cookie';


import "./InfoCollector.css"
import {DonationButton} from '../Donation/Donation'
//uses some css from landingpage.css

const { GA4PUBKEY } = require('../../constants.json')


export default function InfoCollector(props) {

	const navigate = useNavigate()

	const { gtagActive } = useContext(TagContext)

	const donationArr = [
		{amount: "15", text:"$15"},
		{amount: "38", text:"$38"},
		{amount: "50", text:"$50"},
		{amount: "100", text:"$100"},
	]

	const size = useScreenSize()

    const [errArr, setErrArr] = useState([])
    const [email, setEmail] = useState()
    const [postal, setPostal] = useState()
	const [phone, setPhone] = useState()

	const [cookies, setCookie] = useCookies(['_fbp','_fbc'])

    const handleEmailSignup = (props) => {

		    if(gtagActive){
		    	window.gtag("event","volunteer_attempted")
		      window.gtag("get", GA4PUBKEY, "client_id", clientId => {
		          axios.post('/api/all/submit-volunteer-form',{
		            email, postal, phone, volunteer: true, nameExempt:true, GAclientID: clientId, pathname: window.location.pathname, fbc: cookies._fbc ? cookies._fbc : null, fbp: cookies._fbp ? cookies._fbp : null
		          })
		            .then(res => {
		              if(res.data.error) {
		                setErrArr(res.data.error)
		              } else {
		                navigate('/thank-you')
		              }
		            })
		            .catch(e => console.log(e))
		      })
		    } else {
		          axios.post('/api/all/submit-volunteer-form',{
		            email, postal, phone, volunteer: true, nameExempt:true
		          })
		            .then(res => {
		              if(res.data.error) {
		                setErrArr(res.data.error)
		              } else {
		                navigate('/thank-you')
		              }
		            })
		            .catch(e => console.log(e))
		    }

    }	

    return (
    <Row>    	

    	{ size.width < 992 ?
	    	<Row>
	    		<img style={{width:"100%"}} src={suitRed}/>
	    	</Row> : null
	    }

	    <Row className="info-collector-row" justify='center'>
	        <Col xs={0} lg={9} style={{display:"flex", overflow:"hidden", alignItems:"center", flexDirection:"column", justifyContent:"center"}}> 
	            <img className='info-collector-img' src={redwalk}/>
	        </Col>
	        <Col xs={24} lg={15} className="info-collector-right" >       
	        	<Row className="info-collector-row1" justify='center'>TAKE ACTION. WE CAN CHANGE TORONTO. TOGETHER.</Row>
	        	<Row style={{margin:"1em 0 1em 0"}} justify="space-between">
	        		<div className='info-collector-donate-text'>Donate</div>
					{donationArr.map(x => {
						return <DonationButton key={x.amount} amount={x.amount} text={x.text}/>
					})}
				</Row>
				<Row>
				<div style={{paddingBottom:"1em", color:"white", textAlign:"center", width:"100%", fontSize:"0.5em"}}>
					Payment processing by Stripe, operated by Keyp Technologies
				</div>				
				</Row>
	        	<Row>
	        		<Input onChange={e => setEmail(e.target.value)} className="inputs " size='large' placeholder='Email'/>
		            {errArr.includes("emailOrPhone") ? <div style={{color:"red"}}>Please enter your phone number or email</div> : null}
		            {errArr.includes("emailExists") ? <div style={{color:"red"}}>This email has already been submitted</div> : null}
		        	{errArr.includes("invalidEmail") ? <div style={{color:"red"}}>Invalid email format</div> : null} 
	        	</Row>
	          	<Row style={{marginTop:"1em"}}>
	          		<Col span={8} style={{paddingRight:"1em"}}>
	          			<Input onChange={e => setPostal(e.target.value)} className="inputs " size='large' placeholder='Postal Code'/>
	          			{errArr.includes("postal") ? <div style={{color:"red"}}>Please enter your postal code</div> : null}
	          			{errArr.includes("invalidPostal") ? <div style={{color:"red"}}>Invalid postal code</div> : null}
	          		</Col>
	          		<Col span={16}>
	          			<Input onChange={e => setPhone(e.target.value)} className="inputs " size='large' placeholder='Mobile'/>
			            {errArr.includes("emailOrPhone") ? <div style={{color:"red"}}>Please enter phone number or email</div> : null}
			            {errArr.includes("phoneExists") ? <div style={{color:"red"}}>This phone number has already been submitted</div> : null}	
			            {errArr.includes("invalidPhone") ? <div style={{color:"red"}}>Invalid phone number</div> : null}           		
	          		</Col>
	          	</Row>
	          <Row style={{marginTop:"1em"}} justify="center">
	            <Button onClick={handleEmailSignup} className='email-signup-button' style={{width:"100%", height:"auto"}}>I VOLUNTEER TO BETTER TORONTO</Button>
	          </Row>
	        <Row justify="center" style={{color:"white", width:"100%", marginTop:"1em", fontSize:"0.5em"}}>
	            <Col>Thank you for joining our campaign to elect Jack Yan. By providing your mobile phone number you consent to receive recurring text messages from us. Message & Data Rates may apply. Text STOP to opt out. Please visit our <Link className='white-link' to='/privacy'>Privacy Policy</Link>.</Col>
	        </Row> 	              
	        </Col>
	    </Row>
	 </Row>
    )

}