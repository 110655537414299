import React, { useEffect, useRef, useState, useContext } from 'react';
import { Row, Drawer, Button, Menu, Col, Input } from 'antd';
import { BellOutlined, MenuOutlined, FolderOpenOutlined, StarOutlined, NumberOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Link , useNavigate } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { TagContext } from '../../App.js'

import useScreenSize from "use-screen-size"
import axios from 'axios'

import Donation from '../Donation/Donation'

import landingMain from './red1.JPG'; 
import outdoorPeople from './outdoorpeople.JPG'; 
import arrowblue from './arrow-blue.svg'

import './LandingPage.css'
function LandingPage() {

    const size = useScreenSize()
    let navigate = useNavigate()
    const { gtagActive } = useContext(TagContext)

    const [email, setEmail] = useState()
    const [errArr, setErrArr] = useState([])

    const handleEmailSignup = (props) => {
        window.gtag("event","volunteer_attempted")
        axios.post('/api/all/submit-volunteer-form',{
          email, volunteer: false
        })
          .then(res => {
            if(res.data.error){
                setErrArr(res.data.error)
            } else {
              if(gtagActive){
                window.gtag('event', 'email-signup', {
                  clickedFrom: 'landing-page'
                })
              }              
                navigate('/thank-you')
            }
          })
          .catch(e => console.log(e))
    }

    if(size.width < 768){
        return(
    <div>
            <Helmet>
                <title>Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>   
    <Row>
        <Col span={24}>
          <img style={{width:"100%"}} src={landingMain}/>
        </Col>
    </Row>
    <Row justify='center'>
        <Col className='landing-first-row-left' span={24}>
          <Row justify="center" className='landing-elect-jack'>ELECT JACK FOR MAYOR</Row>
          <Row justify="center" style={{fontSize:"2em", fontWeight:800}}>VOTE FOR CHANGE</Row>
          <Row style={{marginTop:"2em"}}justify="center">
            <Input onChange={e => setEmail(e.target.value)} className="inputs" size='large' placeholder='Email'/>
            {errArr.includes("email") ? <div style={{color:"red"}}>Please enter your email</div> : null}
            {errArr.includes("emailExists") ? <div style={{color:"red"}}>This email has already been submitted</div> : null}
             {errArr.includes("invalidEmail") ? <div style={{color:"red"}}>Invalid email format</div> : null}
          </Row>
          <Row style={{marginTop:"1em"}} justify="center">
            <Button onClick={handleEmailSignup} className='email-signup-button' style={{width:"100%", height:"auto"}}><span>I WANT A BETTER TORONTO</span></Button>
          </Row>
        <Row justify="center" style={{color:"white", width:"100%", marginTop:"1em", fontSize:"0.5em"}}>
            <Col>Thank you for joining our campaign to elect Jack Yan. Please visit our <Link className='white-link' to='/privacy'>Privacy Policy</Link>.</Col>
        </Row>                     
        </Col>
    </Row>
    <Row>
        
        <img style={{width:"100%"}}src={outdoorPeople}/>        
    </Row>    
    <Row justify='center'>
        <Col span={24} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <Row justify="center" style={{color: "#1D2D5C", fontWeight: 800, padding: "3em 5em 3em 5em", width:"100%"}}>
                <p style={{fontSize:"2em"}}>
                    A BETTER TORONTO IS POSSIBLE
                </p>
                <p>
                     Torontonians have a choice on October 24, to waste their next four years
                    under an anemic John Tory, or elect new leadership to solve some of Toronto's
                    most pressing issues such as skyrocketing homelessness, crime, and the housing crisis.
                </p>
            </Row>
            <Row justify="center" style={{paddingLeft: "5em", paddingRight: "5em", paddingBottom:"3em",width:"100%"}}>
                <Link to='/jacks-vision'><Button className='vision-button'>Read about Jack's Vision</Button></Link>
            </Row>
        </Col>       
    </Row>      
    <Row style={{backgroundColor:"#1D2D5C"}}justify='center'>
        <Col span={24}>
            <Donation mobile={true}/>    
        </Col>
    </Row>    


    </div>            
            )        
    } else {

  return (
    <div>
            <Helmet>
                <title>Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>       
    <Row justify='center'>
        <Col className='landing-first-row-left' xs={12} lg={10}>
          <Row justify="center" className='landing-elect-jack'>ELECT JACK FOR MAYOR</Row>
          <Row justify="center" style={{fontSize:"2em", fontWeight:800}}>VOTE FOR CHANGE</Row>
          <Row style={{marginTop:"2em"}}justify="center">
            <Input onChange={e => setEmail(e.target.value)} className="inputs" size='large' placeholder='Email'/>
            {errArr.includes("email") ? <div style={{color:"red"}}>Please enter your email</div> : null}
            {errArr.includes("emailExists") ? <div style={{color:"red"}}>This email has already been submitted</div> : null}
             {errArr.includes("invalidEmail") ? <div style={{color:"red"}}>Invalid email format</div> : null}            
          </Row>
          <Row style={{marginTop:"1em"}} justify="center">
            <Button onClick={handleEmailSignup} className='email-signup-button' style={{width:"100%", height:"auto"}}>I WANT A BETTER TORONTO</Button>
          </Row>
        <Row justify="center" style={{color:"white", width:"100%", marginTop:"1em", fontSize:"0.5em"}}>
            <Col>Thank you for joining our campaign to elect Jack Yan. Please visit our <Link className='white-link' to='/privacy'>Privacy Policy</Link>.</Col>
        </Row>                      
        </Col>
        <Col className='landing-cover-wrapper' xs={12} lg={14}>
          <img className="landing-cover" src={landingMain}/>
        </Col>
    </Row>
    <Row style={{backgroundColor:"#1D2D5C"}}justify='center'>
        <Col span={24}>
        <Donation separator={true}/>    
        </Col>
    </Row>
    <Row justify='center'>
        <Col sm={24} md={13} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <Row justify="center" style={{color: "#1D2D5C", fontWeight: 800, padding: "3em 5em 3em 5em", width:"100%"}}>
                <p style={{fontSize:"2em"}}>
                    A BETTER TORONTO IS POSSIBLE
                </p>
                <p>
                    Torontonians have a choice on October 24, to waste their next four years
                    under an anemic John Tory, or elect new leadership to solve some of Toronto's
                    most pressing issues such as skyrocketing homelessness, crime, and the housing crisis.
                </p>
            </Row>
            <Row justify="center" style={{paddingLeft: "5em", paddingRight: "5em", paddingBottom: "3em", width:"100%"}}>
                <Link to='/jacks-vision'><Button className='vision-button'>Read about Jack's Vision</Button></Link>
            </Row>
        </Col>       
        <Col className='landing-better-toronto-img-wrapper' sm={0} md={11}>
          <img className='landing-better-toronto-img' style={{width:"100%"}}src={outdoorPeople}/>
        </Col>     
    </Row>  
    </div>
  )
};
}

export default LandingPage;
