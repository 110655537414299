import { createSlice } from '@reduxjs/toolkit';

export const adSlice = createSlice({
    name: 'ad',
    initialState: {
        platform:null, //ad platform name
        directive:null, //specific actions, like show popup vs not
        shouldShowPopup: true
    },
    reducers: {
        setAdPlatform: (state, action) => {
            state.platform = action.payload.platform
            state.directive = action.payload.directive
        },
        setPopup: (state, action) => {
            state.shouldShowPopup = action.payload
        }
    }
});


export const {
    setAdPlatform,
    setPopup 
} = adSlice.actions;

export const selectAdState = state => state.ad;
export const selectPopup = state => state.ad.shouldShowPopup;

export default adSlice.reducer;
