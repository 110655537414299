import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios'
import {Row, Col, Button} from 'antd'
import smile from './smile-casual.JPG'
import useScreenSize from 'use-screen-size'
import { TagContext } from '../../App.js'
import {Helmet} from "react-helmet";
import { useCookies } from 'react-cookie';


import './CheckoutPage.css'

const { GA4PUBKEY } = require('../../constants.json')

const CheckoutDonationButton = (props) => {

	const [cookies, setCookie] = useCookies(['_fbp','_fbc'])	


	const { gtagActive } = useContext(TagContext)

	const handleClick = (mode) => {

		if(gtagActive){
			window.gtag("event","donation_attempted")
			window.gtag("get", GA4PUBKEY, "client_id", clientId => {
				if(mode == 'custom'){
					axios.post('/api/all/create-checkout-session', {GAclientID: clientId, pathname: window.location.pathname, fbc: cookies._fbc ? cookies._fbc : null, fbp: cookies._fbp ? cookies._fbp : null})
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))		
				} else {
					axios.post('/api/all/create-checkout-session',{amount: mode, GAclientID: clientId, pathname: window.location.pathname, fbc: cookies._fbc ? cookies._fbc : null, fbp: cookies._fbp ? cookies._fbp : null})
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))	
				}
			})
		} else {
				if(mode == 'custom'){
					axios.post('/api/all/create-checkout-session',)
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))		
				} else {
					axios.post('/api/all/create-checkout-session',{amount: mode})
					.then(res => {
						window.location.href = res.data.link
					})
					.catch(err => console.log(err))	
				}
		}

	}

	const propClass = props.cssClass ? props.cssClass : "checkout-donate-buttons"
	const finalStyle= props.divStyle ? props.divStyle : {height:"auto"}

	return(
		<Button style={finalStyle} className={propClass} onClick={() => handleClick(props.amount)}><div className={props.cssClass}><div>{props.text}</div></div></Button>
	)
}	

const CheckoutPage = props => {



const size = useScreenSize()


	const donationArr = [
		{amount: "25", text:"$25"},
		{amount: "100", text:"$100"},
		{amount: "250", text:"$250"},
		{amount: "1000", text:"$1000"},
	]

	return(
		<Row>
            <Helmet>
                <title>Donate | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>   		
			<Col className='checkout-left' md={14} lg={12}>
				<Row style={{textAlign:"left", marginBottom:"2em"}}>
					<Col span={24}>
						<div className='blue-text-800' style={{marginBottom:"0.5em", fontSize:"calc(33px + 17 * ((100vw - 375px) / 1065))"}}>Donate to help us build a better Toronto.</div>
						<div style={{marginBottom:"1em", fontSize:"2em"}}>Together, we can end eight years of mediocrity 
						under John Tory and usher in a new era of prosperity 
						for Toronto.</div>
						<div className="blue-text-800" style={{fontSize:"2em"}}>Choose an amount:</div>
					</Col>
				</Row>
				<Row justify="space-between">
						{donationArr.map(x => {
							return <CheckoutDonationButton key={x.amount} amount={x.amount} text={x.text}/>
						})}
				</Row>
				<Row style={{marginTop:"1em"}}>
					<CheckoutDonationButton divStyle={{width: "100%", height:"auto"}}amount={'custom'} text={"Other"}/>
				</Row>
				<Row style={{textAlign:"left", fontSize:"0.75em", marginTop:"1em"}}>
					Payment processing by Stripe, operated by Keyp Technologies
				</Row>
			</Col>
			<Col className='checkout-img-wrapper' md={10} lg={12}>
			{size.width < 768 ? 
				null
				:
				<img className='checkout-img' style={{width:"100%"}}src={smile}/>
			}
			</Col>
		</Row>
	)
}

export default CheckoutPage
