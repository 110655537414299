import './App.css';
import 'antd/dist/antd.min.css';
import React, { useEffect, useRef, useState, createContext } from 'react';
import { Row, Col, Drawer, Button, Menu, Input } from 'antd';
import { BellOutlined, MenuOutlined, FolderOpenOutlined, StarOutlined, NumberOutlined, SnippetsOutlined } from '@ant-design/icons';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Routes, Route, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import { CookiesProvider, useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import CheckoutPage from './components/CheckoutPage/CheckoutPage'
import LandingPage from './components/LandingPage/LandingPage'
import Navbar from './components/Navbar/Navbar'
import MeetJack from './components/MeetJack/MeetJack'
import Issues from './components/Issues/Issues'
import Volunteer from './components/Volunteer/Volunteer'
import InfoCollector from './components/Footer/InfoCollector'
import Footer from './components/Footer/Footer'
import IssuesText from './components/Issues/IssuesText'
import SuccessInfoCollection from "./components/SuccessPages/SuccessInfoCollection"
import SuccessDonation from "./components/SuccessPages/SuccessDonation"
import Privacy from "./components/Privacy/Privacy"
import EmailUnsubSpecific from "./components/Unsubscribe/EmailUnsubSpecific"
import FinalLetter from "./components/Issues/FinalLetter"
import { setAdPlatform, setPopup } from './redux/features/adSlice'
import whiteRedTie from './components/Banners/white_red_tie.jpg'
import { Turn as Hamburger } from 'hamburger-react'
import useScreenSize from 'use-screen-size'

const { GA4PUBKEY } = require('./constants.json')

const stripePromise = loadStripe(
'pk_live_51LKT7MEljHXbE8LX8qQH8JyhNxobRv1Y2I0op3dU6XDDEl4BxgFC255G0hG51gC4NvF2tHkAmH8YmOiJD1ssJUoQ00Yuk898XG'
);

/*
red color rgb(255, 52, 62)
#1D2D5C dark blue
*/

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};


export const TagContext = createContext()

function App() {
        let navigate = useNavigate()
    const handleEmailSignup = (props) => {
        window.gtag("event","volunteer_attempted")
        axios.post('/api/all/submit-volunteer-form',{
          email, volunteer: false
        })
          .then(res => {
            if(res.data.error){
                setErrArr(res.data.error)
            } else {
                window.gtag('event', 'email-signup', {
                  clickedFrom: 'landing-page'
                })
                setPopupVisible(false)
                navigate('/thank-you')
            }
          })
          .catch(e => console.log(e))
    }        
            const [email, setEmail] = useState()
    const [errArr, setErrArr] = useState([])
        const size = useScreenSize()
        const [popupVisible, setPopupVisible] = useState(true)

                const dispatch =  useDispatch()
        let [searchParams, setSearchParams] = useSearchParams();
        const [check, setCheck] = useState(false)
        useEffect(() => {
                axios.get(`https://www.googletagmanager.com/gtag/js?id=${GA4PUBKEY}`)
                        .then(() => setCheck(true))                                
                        .catch(() => setCheck(false))
                const platform = searchParams.get('platform')
                const directive = searchParams.get('directive')
                if(platform) {
                        dispatch(setAdPlatform({platform:platform, directive: directive}))
                }                        
                searchParams.delete('platform')
                searchParams.delete('directive')
                setSearchParams(searchParams)
        },[])
        return (
                <CookiesProvider>
                <TagContext.Provider value={{gtagActive: check}}>
                        <div className="App">
                       {popupVisible && <div className='fixedPopup'>
                                <Row>
                                        {/*<img style={{width:"100%"}} src={whiteRedTie}/>*/}
                                  <div className="video-responsive">
    {size.width > 853 && <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/DmRSvyyWcFE`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />}
    {size.width <= 853 && size.width > 500 && <iframe
      width="500"
      height="281"
      src={`https://www.youtube.com/embed/DmRSvyyWcFE`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />}
    {size.width <= 500 && <iframe
      width="300"
      height="169"
      src={`https://www.youtube.com/embed/DmRSvyyWcFE`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />}                                             
                                  </div>                                        
                                </Row>
                                <Row style={{margin:"1.5em 1.5em 0.5em 1.5em"}}>

                           My deepest thanks to my supporters. I wish that I had more time to reach out to 
                           more Torontonians, but I am happy that my message has made its way to you. I hope that I have sparked conversations around difficult topics that the council must discuss over the next four years. I eagerly await the council's solutions. Please consider adding yourself to my mailing list to stay in touch. Until then.
                 </Row>
                 <Row justify="center">
            <Input onChange={e => setEmail(e.target.value)} style={{width: "80%"}}className="inputs" size='medium' placeholder='Email'/>
            {errArr.includes("email") ? <div style={{color:"red"}}>Please enter your email</div> : null}
            {errArr.includes("emailExists") ? <div style={{color:"red"}}>This email has already been submitted</div> : null}
             {errArr.includes("invalidEmail") ? <div style={{color:"red"}}>Invalid email format</div> : null}
          </Row>
          <Row style={{marginTop:"0.25em"}} justify="center">
            <Button onClick={handleEmailSignup} className='email-signup-button' style={{width:"80%", height:"auto"}}><span>Sign Up</span></Button>
          </Row>
                                {size.width <= 320 && <Row justify='center' style={{margin:"0.25em 1.5em 0.5em 1.5em"}}>
                                        <div className="closeButton" onClick={() => setPopupVisible(!popupVisible)}>Close</div>
                                </Row>}                                
<Row justify="center" style={{margin:"0.25em 1.5em 0.5em 1.5em"}}> This site will be archived in its current state. Some buttons may no longer work.</Row>        
                                {size.width > 320 && <Row justify='center' style={{margin:"0.25em 1.5em 0.5em 1.5em"}}>
                                        <div className="closeButton" onClick={() => setPopupVisible(!popupVisible)}>Close</div>
                                </Row>}                          
                        </div>}
                        <Navbar/>
                                <Row style={{zIndex:-1}}>
                                <Col span={24} >
                                <ScrollToTop>
                                <Routes>
                                    <Route exact path="/" element={<LandingPage />}></Route>
                                    <Route path="/donate" element={<CheckoutPage/>}></Route>
                                    <Route path="/meet-jack" element={<MeetJack/>}></Route>
                                    <Route path="/jacks-vision" element={<Issues/>}></Route>
                                    <Route path="/volunteer" element={<Volunteer/>}></Route>
                                    <Route path="/thank-you" element={<SuccessInfoCollection/>}></Route>
                                    <Route path="/privacy" element={<Privacy/>}></Route>
                                    <Route path="/success" element={<SuccessDonation/>}></Route>
                                    <Route path="/email-unsubscribe" element={<EmailUnsubSpecific/>}></Route>
                                    <Route path="/john-tory-fools-toronto-with-fake-conservatism" element={<FinalLetter/>}></Route>
                                    <Route path="/final-letter" element={<FinalLetter/>}></Route>
                                    <Route path="/:issues" element={<IssuesText/>}></Route>
                                </Routes> 
                                </ScrollToTop>
                                </Col>
                                </Row>
                        <Row>
                         <Col span={24}>
                        <InfoCollector/>
                        <Footer/>
                                </Col>
                        </Row>
                        </div>
                </TagContext.Provider>
                </CookiesProvider>
        );
}

export default App;
