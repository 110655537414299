//Navbar.js
import React,{useState, useEffect} from 'react';
import { Button, Drawer, Row, Col } from 'antd'
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom'
import useScreenSize from 'use-screen-size'
import { Turn as Hamburger } from 'hamburger-react'
import './Navbar.css';
import logo from './logo.png'
import logoReverse from './logo-reverse.png'

const Navbar = (props) => {

    const [visible, setVisible] = useState(false)
    const size = useScreenSize()
    const [letterVis, setLetterVis] = useState(true)

      let navigate = useNavigate();

    const DonateButton = (props) => {

        const onDonateClick = () => {
            navigate('/donate')
        }

        return(
        <Button onClick={() => onDonateClick()} style={{height:"auto"}} shape="round" className='navbar-donate-btn'>DONATE</Button>
            )
    }

    const MobileMenu = () => {

                    const [isOpen, setOpen] = useState(false)

        return (
            <div className={isOpen ? 'navbar-wrapper-cross' : 'navbar-wrapper'} style={{position:"sticky", top:0, zIndex:3}}>
                <Row>
                <Col span={8}>
                    <div style={{marginLeft: "0.5em"}}>               
                        <Hamburger color={isOpen ? "white" : "#1D2D5C"} toggled={isOpen} toggle={setOpen} />
                    </div>
                </Col>
                <Col style={{placeSelf:"center"}} span={8}>
                        <Link to='/'>{isOpen ? <img style={{height:"2.3em"}} src={logoReverse}/> : <img style={{height:"2.3em"}} src={logo}/>}</Link>
                </Col>
                <Col  span={8}>
                   <div className='navbar-right-icon'>
                       {isOpen ? null : <DonateButton />}
                    </div>                     
                </Col>
                <div className='drawer-wrapper' style={{ position: 'relative'}}>
                    <Drawer
                        placement="top"
                        height="100%"
                        width="100%"
                        closable={false}
                        // onClose={this.onClose}
                        visible={isOpen}
                        mask={false}
                        destroyOnClose={true}
                        zIndex={1}
                        drawerStyle={{backgroundColor:"#1D2D5C"}}
                    >
                     <div classN>       
                        <div className='navbar-text-mobile-wrapper' style={{marginTop:"5em"}}><Link onClick={setOpen} className="navbar-text-mobile" to='/meet-jack'>MEET JACK</Link></div>
                        <div className='navbar-text-mobile-wrapper'><Link onClick={setOpen} className="navbar-text-mobile" to='/jacks-vision'>JACK'S VISION</Link></div>
                        <div className='navbar-text-mobile-wrapper'><Link onClick={setOpen} className="navbar-text-mobile" to='/volunteer'>VOLUNTEER</Link></div>
                        <div className='navbar-text-mobile-wrapper'><Link onClick={setOpen} className="navbar-text-mobile" to='/donate'>DONATE</Link></div>
                    </div>
                    </Drawer>
                </div>
                </Row>
                {!isOpen && letterVis && <Link to='/final-letter' onClick={() => setLetterVis(false)}>
              <Row justify="center" style={{cursor:"pointer", backgroundColor:"rgb(169 44 42)"}}>
                <div style={{textAlign:"center", color:"white"}}>Read Jack Yan's Final Letter before the Election</div>
              </Row>
          </Link>}
            </div >

        )
    }

    const Menu = (props) => {
        return (
        <div>
          <Row className='navbar' justify="center">
            <span className="navbar-text-wrapper"><Link className="navbar-text" to='/meet-jack'>MEET JACK</Link></span>
            <span className="navbar-text-wrapper"><Link className="navbar-text" to='/jacks-vision'>JACK'S VISION</Link></span>
            <span className="navbar-text-wrapper" style={{margin:"0em 0em 0em 0em"}}><Link className="navbar-text" to='/'><img style={{width:"100%"}}src={logo}/></Link></span>           
            <span className="navbar-text-wrapper"><Link className="navbar-text" to='/volunteer'>VOLUNTEER</Link></span>
            <span className="navbar-text-wrapper"><Link className="navbar-text" to='/donate'>DONATE</Link></span>
          </Row>
          {letterVis && <Link to='/final-letter' onClick={() => setLetterVis(false)}>
              <Row justify="center" style={{cursor:"pointer", backgroundColor:"rgb(169 44 42)"}}>
                <div style={{textAlign:"center", color:"white"}}>Read Jack Yan's Final Letter before the Election</div>
              </Row>
          </Link>}
        </div>
        )
    }

   return (
    size.width < 768 ? <MobileMenu/> : <Menu/>
   )
};

export default Navbar;