import React, {useState, useEffect, useContext} from 'react';
import { Row, Col, Button, Checkbox, Form, Input } from 'antd';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { TagContext } from '../../App.js'
import {useNavigate, Link} from 'react-router-dom'
import { useCookies } from 'react-cookie';

import './Volunteer.css'
import volunteerpic from "./short-sleeve-look-up.JPG"

const { GA4PUBKEY } = require('../../constants.json')

const Volunteer = (props) => {

  const navigate = useNavigate()

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [postal, setPostal] = useState()
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()
  const [errArr, setErrArr] = useState([])

  const [cookies, setCookie] = useCookies(['_fbp','_fbc'])

  const { gtagActive } = useContext(TagContext)

  const onSubmit = () => {

    if(gtagActive){
      window.gtag("event","volunteer_attempted")
      window.gtag("get", GA4PUBKEY, "client_id", clientId => {
          axios.post('/api/all/submit-volunteer-form',{
            firstName, lastName, postal, phone, email, volunteer: true, GAclientID: clientId, pathname: window.location.pathname, fbc: cookies._fbc ? cookies._fbc : null, fbp: cookies._fbp ? cookies._fbp : null
          })
            .then(res => {
              if(res.data.error) {
                setErrArr(res.data.error)
              } else {
                navigate('/thank-you')
              }
            })
            .catch(e => console.log(e))
      })
    } else {
          axios.post('/api/all/submit-volunteer-form',{
            firstName, lastName, postal, phone, email, volunteer: true
          })
            .then(res => {
              if(res.data.error) {
                setErrArr(res.data.error)
              } else {
                navigate('/thank-you')
              }
            })
            .catch(e => console.log(e))
    }
  }

  return (
  	<Row justify='space-between' style={{alignItems:"center", backgroundColor:"#1D2D5C"}}>
            <Helmet>
                <title>Volunteer | Jack Yan for Mayor of Toronto: Official Campaign Website</title>
            </Helmet>         
      <Col xs={24} lg={12} className='volunteer-column-wrapper'>
        <Row className="volunteer-row1">TAKE ACTION.</Row>
        <Row className="volunteer-row1">SPREAD THE WORD.</Row>
        <Row className="volunteer-row2">Toronto can’t get better without you on this team.</Row>
        <Row justify="center" >
          <Col span={16} style={{paddingRight:"1em"}}>
            <Input className='volunteer-input' onChange={e => setFirstName(e.target.value)} placeholder="First name"/>
            {errArr.includes("firstName") ? <div style={{color:"red"}}>Please enter your first name</div> : null}
          </Col>
          <Col span={8}>
            <Input className='volunteer-input' onChange={e => setLastName(e.target.value)} placeholder="Last name"/>
            {errArr.includes("lastName") ? <div style={{color:"red"}}>Please enter your last name</div> : null}
          </Col>
            <Input className='volunteer-input' onChange={e => setPostal(e.target.value)} placeholder="Postal code"/>
            {errArr.includes("postal") ? <div style={{color:"red"}}>Please enter your postal code</div> : null}
            {errArr.includes("invalidPostal") ? <div style={{color:"red"}}>Invalid postal code</div> : null}
            <Input className='volunteer-input' onChange={e => setPhone(e.target.value)} placeholder="Phone number"/> 
            {errArr.includes("emailOrPhone") ? <div style={{color:"red"}}>Please enter phone number or email</div> : null}
            {errArr.includes("phoneExists") ? <div style={{color:"red"}}>This phone number has already been submitted</div> : null}
            {errArr.includes("invalidPhone") ? <div style={{color:"red"}}>Invalid phone number</div> : null}
            <Input className='volunteer-input' onChange={e => setEmail(e.target.value)} placeholder="Email"/>
            {errArr.includes("emailOrPhone") ? <div style={{color:"red"}}>Please enter your phone number or email</div> : null}
            {errArr.includes("emailExists") ? <div style={{color:"red"}}>This email has already been submitted</div> : null}
            {errArr.includes("invalidEmail") ? <div style={{color:"red"}}>Invalid email format</div> : null}        
        </Row>
        <Row justify="center" >
            <Button onClick={onSubmit} className='join-team-button'>Join The Team</Button>
        </Row>
          <Row justify="center" style={{color:"white", width:"100%", marginTop:"1em", fontSize:"0.5em"}}>
              <Col>Thank you for joining our campaign to elect Jack Yan. By providing your mobile phone number you consent to receive recurring text messages from us. Message & Data Rates may apply. Text STOP to opt out. Please visit our <Link className='white-link' to='/privacy'>Privacy Policy</Link>.</Col>
          </Row>              
      </Col>
      <Col className='volunteer-img-wrapper' xs={0} lg={12}>
        <img className='volunteer-img' src={volunteerpic}/>
      </Col>
    </Row>
  );
};

export default Volunteer